<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="4" v-show="!edition">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'pfms_list'"
          :AllResponse="'allpfms_list'"
          :title="tiertype == 1 ? 'Proformas Clients' : 'Offres Fournisseurs'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'Proformas'"
          :sheetname="'Proformas'"
          @open="NewPfmForm"
          @rowselect="PfmChange"
          :showedit="false"
          :add_disable="loading"
          :key="klist"
          :TypeScope="tiertype"
          :Add="editer"
          :del="false"
          :search_elm="filtre"
        >
        </listitemspage>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
      <v-col cols="12" sm="12" md="8">
        <pfmprint
          :key="kprint"
          :pfm="proforma"
          :tiertype="tiertype"
          :tier_type_prix="tier_type_prix"
          :qte_prix="qte_prix"
          :editer="editer"
          :amender="amender"
          :valider="valider"
          :annuler="annuler"
          :convertir="convertir"
          :modes="modes"
          :modalites="modalites"
          :allconsults="allconsults"
          :ports="ports"
          :units="units"
          @cancel="proforma = selitem"
          @change="PfmChange"
          :edition="edition"
          @edition="edit_change"
          @goto="goto_pfm"
          :loading="data_load"
          @options="get_option"
          :ipg="options.itemsPerPage"
        >
        </pfmprint>
      </v-col>
    </v-row>

    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import PFMS_LIST from "../graphql/Proforma/PFMS_LIST.gql";
import PFM from "../graphql/Proforma/PFM.gql";
import ALLPFM_LIST from "../graphql/Proforma/ALLPFM_LIST.gql";
import DATA from "../graphql/Commande/DATA.gql";
import PRICES from "../graphql/Commande/PRICES.gql";
import MODES from "../graphql/Paiement/MODES.gql";
import ALLCONSULTATIONS from "../graphql/Consult/ALLCONSULTATIONS.gql";
import UPDATE_PFM from "../graphql/Proforma/UPDATE_PFM.gql";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    pfmprint: () => import("../components/PfmPrint.vue"),
  },
  props: {
    tiertype: Number,
  },
  data: () => ({
    filtre: "",
    editer: false,
    amender: false,
    valider: false,
    annuler: false,
    convertir: false,
    drawer: null,
    selitem: {},
    edition: false,
    headers: [
      {
        text: "Code",
        value: "nopfm",
        enum: "NOPFM",
        title: true,
        selected: true,
        sm: 4,
        md: 4,
      },

      {
        text: "Date",
        value: "date_pfm",
        enum: "DATE_PFM",
        slot: "date",
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "Tier",
        align: "text-left",
        value: "tier_name",
        enum: "TIER_NAME",
        selected: true,
        sm: 4,
        md: 4,
      },

      {
        text: "Montant HT",
        value: "mont_ht",
        enum: "MONT_HT",
        align: "end",
        slot: "cur",
        selected: true,
        sm: 8,
        md: 8,
      },
      {
        text: "Statut.",
        value: "statut_id",
        selected: false,
        hiden: true,
      },
      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        expire: true,
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "Etablit Par",
        value: "contact",
        enum: "CONTACT",
        selected: false,
      },
    ],
    proforma: {},
    options: {},
    kprint: 100,
    klist: 0,
    loading: false,
    tier_type_prix: [],
    qte_prix: [],
    modes: [],
    modalites: [],
    allconsults: [],
    data_load: false,
    ports: [],
    comptes: [],
    units: [],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    Qselect() {
      return PFMS_LIST;
    },
    Qselectall() {
      return ALLPFM_LIST;
    },
  },
  watch: {
    tiertype: {
      async handler() {
        this.proforma = {};
        this.$store.dispatch("Changed", true);
        await this.get_data();
      },
    },
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre;
        this.klist++;
      },
    },
  },
  async created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  async beforeRouteLeave(to, from, next) {
    if (this.$store.state.isEditing) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Quitter sans enregistrement !! Etês-vous sûr ?",
          { color: "orange darken-3" }
        )
      ) {
        await this.update_modif();
        next();
      } else return;
    } else next();
  },
  async mounted() {
    this.editer =
      (this.tiertype == 1 && this.$store.state.auth.includes("02022")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("02020")) ||
      this.$store.state.isadmin;

    this.valider =
      (this.tiertype == 1 && this.$store.state.auth.includes("03022")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("03020")) ||
      this.$store.state.isadmin;

    this.annuler =
      (this.tiertype == 1 && this.$store.state.auth.includes("06022")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("06020")) ||
      this.$store.state.isadmin;

    this.convertir =
      (this.tiertype == 1 && this.$store.state.auth.includes("07022")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("07020")) ||
      this.$store.state.isadmin;

    this.amender =
      (this.tiertype == 1 && this.$store.state.auth.includes("08022")) ||
      this.$store.state.isadmin;
    await this.get_data();

    if (this.$route.params.pfm) {
      this.filtre = this.$route.params.pfm.nopfm;
      this.selitem = this.$route.params.pfm;
      this.proforma = this.selitem;
      this.kprint++;
      this.klist++;
    }
  },

  methods: {
    get_option(item) {
      this.options = item;
    },
    goto_pfm(item) {
      this.filtre = item.nopfm;
      this.selitem.id = item.id;
      this.proforma = this.selitem;
      this.kprint++;
      this.klist++;
    },
    async preventNav(event) {
      if (!this.$store.state.isEditing) return;
      event.preventDefault();
      event.stopImmediatePropagation();

      //event.returnValue = "";
      await this.update_modif();
      //
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.proforma.id > 0) {
        let v = {
          input: {
            id: this.proforma.id,
            modif_by: null,
            modif_date: null,
          },
        };
        await this.maj(UPDATE_PFM, v);
      }
    },

    async maj(query, v) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },
    edit_change(value) {
      this.edition = value;
    },
    async get_data() {
      this.loading = true;
      let r = await this.requette(MODES);
      if (r) {
        this.modes = r.modes;
        this.modalites = r.modalites;
        this.comptes = r.comptes.filter(
          (elm) => elm.struct_id == this.$store.state.me.struct_id
        );
      }
      r = await this.requette(DATA);
      if (r) {
        this.ports = r.ports;
        this.units = r.units.filter((elm) => elm.uom_type == "m");
      }

      if (this.tiertype == 2) {
        let v = {
          where: { column: "STATUT_ID", value: "7" },
        };
        let r = await this.requette(ALLCONSULTATIONS, v);
        if (r) this.allconsults = r.allconsultations;
      }
      this.loading = false;

      if (this.tiertype == 1) {
        let r = await this.requette(PRICES);
        if (r) this.tier_type_prix = r.tier_type_prix;
        if (r) this.qte_prix = r.qte_prix;
      }
    },
    async requette(query, v) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async PfmChange(item) {
      if (item)
        if (item.id) {
          this.data_load = true;
          let r = await this.requette(PFM, {
            where: { column: "ID", value: item.id },
          });
          if (r) {
            this.proforma = r.pfms.data[0];
            this.kprint++;
            this.data_load = false;
          }
          this.selitem = item;
        }
    },
    NewPfmForm(item) {
      this.edition = true;
      this.$store.dispatch("Editing", true);
      this.kprint++;
      this.proforma = item;
    },
  },
};
</script>
